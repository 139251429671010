<template>
  <section class="c-familiarity container">
    <h2 class="c-familiarity__title">Давай познакомимся</h2>
    <iframe class="c-familiarity__frame" allowfullscreen="" frameborder="0" src="https://www.youtube.com/embed/VrHnjzG3h_0/?rel=0" width="100%"></iframe>
  </section>
</template>

<script setup lang="ts">
</script>

<style scoped lang="scss">
.c-familiarity {
  margin-bottom: 64px;
  @media (max-width: getBreakpoint(tablet)) {
    margin-bottom: 32px;
  }

  &__title {
    margin-bottom: 24px;
    @media (max-width: getBreakpoint(tablet)) {
      margin-bottom: 16px;
    }
  }
  &__frame {
    height: 686px;
    @media (max-width: getBreakpoint(tablet)) {
      margin-left: -16px;
      margin-right: -16px;
      width: 100vw;
      height: calc(100vw / (1220 / 686));
    }
  }
}
</style>